var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", { attrs: { "no-shadow": "" } }, [
    _c(
      "form",
      { ref: "form" },
      [
        _vm.isMobile
          ? _c("div", { staticClass: "flex items-center" }, [
              _c("span", { staticClass: "user-settings__title-inner" }, [
                _vm._v(_vm._s(_vm.$t("vue.changeEmail"))),
              ]),
            ])
          : _vm._e(),
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|validateEmail",
              expression: "'required|validateEmail'",
            },
          ],
          staticClass: "w-full",
          attrs: { "label-placeholder": _vm.$t("inputs.email"), name: "email" },
          model: {
            value: _vm.newEmail,
            callback: function ($$v) {
              _vm.newEmail = $$v
            },
            expression: "newEmail",
          },
        }),
        _c("span", { staticClass: "text-danger text-sm" }, [
          _vm._v(_vm._s(_vm.errors.first("email"))),
        ]),
        _vm.CAN_CHANGE_EMAIL && !_vm.errors.first("email")
          ? _c(
              "div",
              {
                staticClass: "mt-2",
                staticStyle: { display: "flex", "flex-direction": "column" },
              },
              [
                _c("div", { staticClass: "otp-label" }, [
                  _vm._v(_vm._s(_vm.$t("inputs.verificationCode"))),
                ]),
                _c("otp-input", {
                  ref: "otpInput",
                  attrs: {
                    "label-placeholder": _vm.$t("inputs.email"),
                    "input-classes": "otp-input",
                    separator: " ",
                    "num-inputs": 6,
                    "should-auto-focus": true,
                    "is-input-num": true,
                  },
                  on: {
                    "on-change": _vm.handleOnChange,
                    "on-complete": _vm.handleOnComplete,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center justify-end" },
          [
            _vm.CAN_GENERATE_VERIFICATION_CODE
              ? _c(
                  "vs-button",
                  {
                    staticClass: "ml-auto mt-2",
                    attrs: {
                      disabled:
                        (_vm.errors.first("email") &&
                          _vm.errors.first("email").length > 0) ||
                        _vm.newEmail.length === 0,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.generateVerificationCode(
                          "generate-verification-code"
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("vue.generateVerificationCode")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.CAN_CHANGE_EMAIL
              ? _c(
                  "vs-button",
                  {
                    staticClass: "ml-4 mt-2",
                    attrs: {
                      disabled:
                        (_vm.errors.first("email") &&
                          _vm.errors.first("email").length > 0) ||
                        _vm.newEmail.length === 0 ||
                        !_vm.canChangeEmailAddress,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.updateEmail.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("vue.changeEmail")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "vs-button",
              {
                staticClass: "ml-4 mt-2",
                attrs: { type: "border", color: "warning" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.reset.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("vue.reset")))]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
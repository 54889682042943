<template>
  <div :class="{ 'availability--mobile': isMobile }">
    <div v-if="!isMobile" class="vx-row mb-10">
      <div class="mt-10 vx-col availability__heading">
        <h3 class="availability__heading__title">{{ $t('views.availability.title') }}</h3>
        <p class="availability__heading__description">{{ $t('views.availability.description') }}</p>
      </div>
    </div>

    <div class="availability-container relative">
      <div :class="!HAS_ACCESS ? 'blur-background' : ''">
        <vx-card no-shadow v-if="!isMobile" class="mb-3">
          <div v-if="!!(activeUserInfo.isSyncedCalendar && activeUserInfo.syncedCalendarType)" class="flex items-center">
            <span class="mr-2 availability__title-inner">{{ $t('calendar.calendarSynchronization') }}</span>

            <vx-tooltip class="flex items-center" style="width: 17px" position="top" :title="$t('views.availability.calendarSynchronization.main')" :text="`${$t('views.availability.calendarSynchronization.option_1')} ${$t('views.availability.calendarSynchronization.option_2')} ${$t(
                'views.availability.calendarSynchronization.option_3'
              )}`">
              <img width="17" height="17" :src="infoImg" style="display: inline-block" />
            </vx-tooltip>
          </div>

          <div v-if="!(activeUserInfo.isSyncedCalendar && activeUserInfo.syncedCalendarType)" class="availability-hints__info">
            <vs-icon class="availability-hints__info__icon" icon-pack="material-icons" icon="error" size="22px" color="rgb(240, 85, 65)" />

            {{ $t('views.availability.calendarSynchronization.main') }}

            <ul class="options-list">
              <li class="options-list__item">{{ $t('views.availability.calendarSynchronization.option_1') }}</li>
              <li class="options-list__item">{{ $t('views.availability.calendarSynchronization.option_2') }}</li>
              <li class="options-list__item">{{ $t('views.availability.calendarSynchronization.option_3') }}</li>
            </ul>

            <div class="mt-5 vx-row">
              <div class="vx-col pl-5">
                <vs-button size="medium" color="primary" :disabled="!HAS_ACCESS" class="mr-3 btn-main" @click="showSyncCalendarDialog">
                  {{ $t('calendar.syncCalendar') }}
                </vs-button>
              </div>
            </div>
          </div>

          <div v-else class="calendar-sync-wrapper">
            <div class="calendar-sync__row calendar-sync__heading">
              <div class="calendar-sync__type">
                <div class="calendar-sync__type__img">
                  <img class="service-current-calendar" :src="require(`@/assets/images/calendars/${activeUserInfo.syncedCalendarType}.png`)" :alt="$t('calendar.name')" :title="$t('calendar.name')" />
                </div>

                <div class="calendar-sync__type__info">
                  <div class="calendar-sync__text-title calendar-sync__type__info__title">
                    {{ activeUserInfo.syncedCalendarType.includes('google') ? 'Google' : 'Outlook' }}

                    <img class="ml-3" :src="require('@/assets/images/pages/verified-check.svg')" alt="verified" />
                  </div>

                  <div class="calendar-sync__text-info">{{ syncedEmail }}</div>
                </div>
              </div>

              <div class="flex calendar-sync__buttons-wrapper">
                <vs-button class="mb-base mr-3" color="primary" type="border" icon="refresh" @click.prevent="getCalendars()"> </vs-button>

                <vx-tooltip :text="$t('vue.delete')" class="inline-flex" position="top">
                  <vs-button :color="'#F05541'" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click.prevent="cancelSync = true"></vs-button>
                </vx-tooltip>
              </div>
            </div>

            <div class="calendar-sync__deatils">
              <div class="calendar-sync__row calendar-sync__deatils__item">
                <div class="calendar-sync__deatils__item__info">
                  <div class="calendar-sync__text-title">{{ $t('calendar.chooseYourCalendar') }}</div>
                  <div class="calendar-sync__text-info">{{ $t('calendar.chooseYourCalendarDescription') }}</div>
                </div>

                <multiselect v-if="!isLoadingCalendarIdsMultiselect" ref="calendarIdsList" v-model="syncedCalendar" :options="calendarIdsListOptions" @select="updateSelectedSyncedCalendar" :multiple="false" :close-on-select="true" :clear-on-select="false" :placeholder="''" :selectLabel="''" :selectedLabel="''" :deselectLabel="''" label="name" track-by="id" open-direction="bottom" data-vv-as="name" :preserve-search="true" :preselect-first="false" :allow-empty="false">
                </multiselect>
              </div>

              <div class="calendar-sync__row calendar-sync__deatils__item">
                <div class="calendar-sync__deatils__item__info">
                  <div class="calendar-sync__text-title">{{ $t('calendar.onlineMeetingTool') }}</div>
                  <div class="calendar-sync__text-info">{{ $t('calendar.onlineMeetingToolDescription') }}</div>
                </div>

                <multiselect v-if="onlineMeetingToolsFiltered && onlineMeetingToolsFiltered.length > 0" @select="updateSelectedOnlineMeetingTool" ref="onlineMeetingTools" v-model="onlineMeetingTool" :options="onlineMeetingToolsFiltered" :multiple="false" :close-on-select="true" :clear-on-select="false" :placeholder="''" :selectLabel="''" :selectedLabel="''" :deselectLabel="''" label="name" track-by="id" open-direction="bottom" data-vv-as="name" :preserve-search="true" :preselect-first="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <div class="flex items-center">
                      <img width="20" height="20" class="mr-3" style="object-fit: contain;" :src="require(`@/assets/images/calendars/${option.img}`)" :alt="$t('calendar.name')" />
                      {{ option.name }}
                    </div>
                  </template>

                  <template slot="option" slot-scope="{ option }">
                    <div class="flex items-center">
                      <img width="20" height="20" class="mr-3" style="object-fit: contain;" :src="require(`@/assets/images/calendars/${option.img}`)" :alt="$t('calendar.name')" />
                      {{ option.name }}
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </vx-card>

        <vx-card no-shadow>
          <connect-modal :show="timeRangeDialog" :width="'460px'" :height="'300px'" bkcolor="#F7F7F7">
            <div class="flex flex-row visitor-list-delete-all">
              <div>
                <h3>{{ $t('inputs.selectTimeRange') }}</h3>
              </div>
              <div disabled style="margin-left: auto; cursor: pointer" @click="timeRangeDialog = false">
                <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
              </div>
            </div>
            <div class="flex flex-wrap justify-center" style="height: 200px">
              <!-- 12-hour format, with AM/PM picker -->
              <vs-row w="12">
                <vs-col w="12">
                  <label class="text-sm">{{ $t('inputs.start') }}</label>
                </vs-col>
              </vs-row>

              <vue-timepicker close-on-complete class="w-full mb-base" format="HH:mm" :minute-interval="minuteInterval" v-model="start" @change="timeChange" fixed-dropdown-button drop-direction="down" />

              <vs-row w="12">
                <vs-col w="12">
                  <label class="text-sm">{{ $t('inputs.end') }}</label>
                </vs-col>
              </vs-row>

              <vue-timepicker close-on-complete drop-direction="up" fixed-dropdown-button hide-disabled-items class="w-full mb-base" format="HH:mm" :hour-range="hourRange" :minute-range="minuteRange" :minute-interval="minuteInterval" v-model="end" @change="timeChange" @error="errorHanlder" />

              <vs-button class="w-full mb-base" color="primary" type="filled" icon="add" :disabled="hasError || !HAS_ACCESS" @click.prevent="addUpdateTimeRange">
                {{ $t('vue.addTimeRange') }}
              </vs-button>
            </div>
          </connect-modal>

          <connect-modal :show="syncCalendarDialog" :width="'460px'" :height="'300px'" bkcolor="#F7F7F7">
            <div class="calendar-sync-container">
              <div class="flex flex-row">
                <div>
                  <h3>{{ $t('calendar.syncMyCalendar') }}</h3>
                </div>
                <div disabled style="margin-left: auto; cursor: pointer" @click="syncCalendarDialog = false">
                  <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
                </div>
              </div>
              <div class="my-4 service-list-instruction">{{ $t('calendar.chooseAccount') }}:</div>

              <div class="service-list">
                <div v-for="calendar in calendarList" class="service-list__item" :key="calendar.type">
                  <a class="service-list__link" href="#" @click.prevent="syncCalendar(calendar.type)">
                    <img class="service-list__img" :src="require(`@/assets/images/calendars/${calendar.type}.png`)" :alt="`${calendar.title} ${$t('calendar.name')}`" :title="`${calendar.title} ${$t('calendar.name')}`" />

                    <div class="service-list__text">{{ calendar.title }} {{ $t('calendar.name') }}</div>
                  </a>
                </div>
              </div>
            </div>
          </connect-modal>

          <form ref="form">
            <div v-if="!isMobile" class="mt-5 flex flex-wrap items-center mb-base">
              <multiselect ref="timezones" v-model="activeUserInfo.timezone" :options="timezones" :multiple="false" :close-on-select="true" :clear-on-select="false" :placeholder="''" :selectLabel="'Select timezone'" :selectedLabel="''" :deselectLabel="''" label="name" track-by="name" open-direction="bottom" v-validate="'required'" data-vv-as="timezone" :preserve-search="true" :preselect-first="false">
              </multiselect>
            </div>
            <div class="flex items-center">
              <span class="user-settings__title-inner availability__title-inner" :class="{ 'mr-2': !isMobile, 'mr-3': isMobile }">{{
                $t('vue.availability')
              }}</span>

              <vx-tooltip v-if="!isMobile && activeUserInfo.availableTimeSlots && !!activeUserInfo.availableTimeSlots.find((slot) => slot.enabled)" position="top" class="flex items-center" style="width: 17px" :text="$t('views.availability.availability.main')">
                <img width="17" height="17" :src="infoImg" style="display: inline-block" />
              </vx-tooltip>

              <popper v-if="isMobile" trigger="clickToToggle" append-to-body="true" root-class="availability__popper-wrapper" :options="{
                  placement: 'bottom',
                  modifiers: { offset: { offset: '0,10px' } }
                }">
                <div class="popper custom-popper">
                  {{ $t('views.availability.availability.main') }}
                </div>

                <label slot="reference" class="flex">
                  <img width="17" height="17" :src="infoImg" style="display: inline-block" />
                </label>
              </popper>
            </div>

            <div v-if="activeUserInfo.availableTimeSlots && !activeUserInfo.availableTimeSlots.find((slot) => slot.enabled)" class="availability-hints__info">
              <vs-icon class="availability-hints__info__icon" icon-pack="material-icons" icon="error" size="22px" color="rgb(240, 85, 65)" />

              {{ $t('views.availability.availability.main') }}
            </div>

            <div class="mt-5 flex flex-wrap items-center mb-base">
              <vs-row v-for="(slot, d) in availableTimeSlots" :key="d">
                <div class="vx-row slot-wrapper">
                  <div class="vx-col w-full">
                    <vs-checkbox hide-details class="ma-0 pt-0 pb-1 checkbox-main" v-model="slot.enabled" @change="slotUpdated(slot, d)">
                      {{ $t(modifyDayNameToLong(slot.day)) }}
                    </vs-checkbox>

                    <div v-if="slot.enabled" class="slot-details">
                      <div v-for="(time, t) in slot.times" class="slot-item" :key="t">
                        <div class="slot-item__time">{{ format12HrTime(time.start) }} - {{ format12HrTime(time.end) }}</div>

                        <div class="slot-item__actions">
                          <vx-tooltip :text="$t('vue.edit')" class="inline-flex" position="top">
                            <vs-button :disabled="!HAS_ACCESS" :color="!HAS_ACCESS ? '#c4c4c4' : '#3B86F7'" type="border" size="medium" icon-pack="feather" icon="icon-edit" @click.prevent="editTime(d, t, time)"></vs-button>
                          </vx-tooltip>

                          <vx-tooltip :text="$t('vue.delete')" class="inline-flex" position="top">
                            <vs-button :disabled="!HAS_ACCESS" :color="!HAS_ACCESS ? '#c4c4c4' : '#F05541'" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click.prevent="deleteTime(t, slot)"></vs-button>
                          </vx-tooltip>
                        </div>
                      </div>

                      <vs-button size="medium" color="primary" :disabled="!HAS_ACCESS" class="btn-main btn-add" @click="addTime(d)">
                        <span class="btn-add__label">{{ $t('inputs.add') }}</span>
                      </vs-button>
                    </div>
                  </div>
                </div>
              </vs-row>
            </div>
          </form>
        </vx-card>
      </div>
      <availability-message v-if="!HAS_ACCESS"></availability-message>
    </div>

    <vs-prompt :title="$t('vue.cancelSynchronization')" @cancel="cancelSync = false" @accept="cancelSyncedCalendar" @close="cancelSync = false" color="danger" :cancel-text="$t('vue.cancel')" :accept-text="$t('vue.confirm')" :active.sync="cancelSync">
      <div class="con-exemple-prompt">
        {{ $t('vue.cancelSynchronizationMessage') }}
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import ct from 'countries-and-timezones'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

import { mapGetters } from 'vuex'
import ConnectModal from '@/components/ConnectModal.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'

import moment from 'moment'
import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

import AvailabilityMessage from '@/components/upgrade-messages/AvailabilityMessage.vue'

export default {
  components: {
    VueTimepicker,
    ConnectModal,
    CloseCircleIcon,
    AvailabilityMessage,
    Multiselect,
    Popper
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      cancelSync: false,
      syncedEmail: '',
      onlineMeetingTools: [
        { id: 'lets-connect', name: 'Let’s Connect Meeting', img: 'lets_connect.png' },
        { id: 'google-meet', name: 'Google Meet', img: 'google_meet.png' },
        { id: 'microsoft-teams', name: 'Microsoft Teams', img: 'microsoft_teams.png' }
      ],
      onlineMeetingTool: '',
      calendarIdsListOptions: [],
      isLoadingCalendarIdsMultiselect: false,
      syncedCalendar: '',
      syncCalendarDialog: false,
      timeRangeDialog: false,
      timeRangeDialogType: null,
      start: {
        HH: '00',
        mm: '00'
      },
      end: {
        HH: '00',
        mm: '00'
      },
      minuteInterval: 10,
      selectedDay: null,
      selectedTime: null,
      currentTimezone: null,
      timezones: [],
      availableTimeSlots: [
        {
          day: 'system.weekdays.short.mon',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.tue',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.wed',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.thu',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.fri',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.sat',
          enabled: false,
          times: []
        },
        {
          day: 'system.weekdays.short.sun',
          enabled: false,
          times: []
        }
      ],
      minuteRange: [[0, 60]],
      hasError: true,
      // previousTimeslots: [],
      calendarList: [
        {
          type: 'google_calendar',
          title: 'Google'
        },
        {
          type: 'outlook_calendar',
          title: 'Outlook'
        }
      ],
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company'
    }),
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('scheduling') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    hourRange() {
      const startHour = parseInt(this.start.HH)

      return startHour === 23 ? [23] : [[startHour, 23]]
    },

    onlineMeetingToolsFiltered() {
      let arr = []

      if (this.activeUserInfo && this.activeUserInfo.isSyncedCalendar) {
        if (this.activeUserInfo.syncedCalendarType === 'google_calendar') {
          arr = this.onlineMeetingTools.filter((item) => item.id !== 'microsoft-teams')
        } else if (this.activeUserInfo.syncedCalendarType === 'outlook_calendar') {
          arr = this.onlineMeetingTools.filter((item) => item.id !== 'google-meet')
        }
      }

      return arr
    }
  },

  watch: {
    'activeUserInfo.timezone'() {
      if (this.activeUserInfo.timezone) {
        this.$db.collection('users').doc(this.activeUserInfo.uid).set({ timezone: this.activeUserInfo.timezone }, { merge: true })
      }
    }
  },

  mounted() {
    const timezones = ct.getAllTimezones()
    for (const timezone in timezones) {
      this.timezones.push(timezones[timezone])
    }

    if (this.activeUserInfo && !this.activeUserInfo.timezone) {
      const timezoneName = dayjs.tz.guess()
      const timezone = this.timezones.find((x) => x.name === timezoneName)
      if (timezone) {
        this.$db.collection('users').doc(this.activeUserInfo.uid).set({ timezone }, { merge: true })
      }
    }

    this.$vs.loading()
    this.getUserAvailability()

    setTimeout(async () => {
      if (this.activeUserInfo && this.activeUserInfo.isSyncedCalendar) {
        await this.getCalendars(this.activeUserInfo.syncedCalendarsList)
        await this.getSelectedOnlineMeetingTool()
      }

      this.$vs.loading.close()
    }, 100)
  },

  methods: {
    showSyncCalendarDialog() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        this.syncCalendarDialog = true
      }
    },

    getSyncedCalendarId() {
      if (this.activeUserInfo && this.activeUserInfo.syncedCalendarId) {
        this.syncedCalendar = this.calendarIdsListOptions.find((item) => item.id === this.activeUserInfo.syncedCalendarId)
      }
    },

    getSelectedOnlineMeetingTool() {
      if (this.activeUserInfo && this.activeUserInfo.syncedCalendarOnlineMeetingToolId) {
        this.onlineMeetingTool = this.onlineMeetingTools.find((item) => item.id === this.activeUserInfo.syncedCalendarOnlineMeetingToolId)
      }
    },

    async updateSelectedSyncedCalendar(value) {
      await this.$vs.loading()

      try {
        await this.$db
          .collection('users')
          .doc(this.activeUserInfo.uid)
          .set(
            { syncedCalendarId: value.id },
            { merge: true }
          )

        this.$store.commit('UPDATE_USER_INFO', {
          syncedCalendarId: value.id,
          closeAnimation: this.$vs.loading.close
        })
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.somethingWentWrong'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

        await this.$vs.loading.close()
      }
    },

    async updateSelectedOnlineMeetingTool(value) {
      await this.$vs.loading()

      try {
        await this.$db
          .collection('users')
          .doc(this.activeUserInfo.uid)
          .set(
            { syncedCalendarOnlineMeetingToolId: value.id },
            { merge: true }
          )

        this.$store.commit('UPDATE_USER_INFO', {
          syncedCalendarOnlineMeetingToolId: value.id,
          closeAnimation: this.$vs.loading.close
        })
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.somethingWentWrong'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

        await this.$vs.loading.close()
      }
    },

    async cancelSyncedCalendar() {
      await this.$vs.loading()

      try {
        await this.$db
          .collection('users')
          .doc(this.activeUserInfo.uid)
          .set(
            {
              isSyncedCalendar: this.$firebase.firestore.FieldValue.delete(),
              syncedEmail: this.$firebase.firestore.FieldValue.delete(),
              syncedCalendarsList: this.$firebase.firestore.FieldValue.delete(),
              syncedCalendarType: this.$firebase.firestore.FieldValue.delete(),
              syncedCalendarId: this.$firebase.firestore.FieldValue.delete(),
              syncedCalendarOnlineMeetingToolId: this.$firebase.firestore.FieldValue.delete()
            },
            { merge: true }
          )

        this.$store.commit('UPDATE_USER_INFO', {
          isSyncedCalendar: false,
          syncedEmail: '',
          syncedCalendarsList: '',
          syncedCalendarType: '',
          syncedCalendarId: '',
          syncedCalendarOnlineMeetingToolId: '',
          closeAnimation: this.$vs.loading.close
        })

        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.removed'),
          text: this.$i18n.t('vue.calendarSynchronizationRemovedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.somethingWentWrong'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

        await this.$vs.loading.close()
      }
    },

    getUserAvailability() {
      const userInfo = { ...this.activeUserInfo }

      if (userInfo && userInfo.availableTimeSlots && userInfo.availableTimeSlots.length > 0) {
        this.availableTimeSlots = [...[]]
        // this.previousTimeslots = [...[]]

        this.availableTimeSlots = _.cloneDeep([...userInfo.availableTimeSlots])
        // this.previousTimeslots = _.cloneDeep(this.availableTimeSlots)
      }
    },

    errorHanlder(e) {
      this.hasError = e.length > 0
    },

    timeChange() {
      const startHour = parseInt(this.start.HH)
      const startMinute = parseInt(this.start.mm)
      const endHour = parseInt(this.end.HH)

      let minuteRange = []

      if (startHour === endHour) {
        const endMinute = startMinute + this.minuteInterval

        minuteRange = endMinute > 60 - this.minuteInterval ? [] : [[endMinute, 60]]
      } else {
        minuteRange = [[0, 60]]
      }

      this.minuteRange = minuteRange
    },

    async updateAvailableTimeSlots() {
      await this.$vs.loading()

      const data = {}

      this.availableTimeSlots = this.availableTimeSlots.map((x) => {
        return {
          enabled: x.times.length > 0,
          times: x.times,
          day: x.day
        }
      })

      const timeslots = _.cloneDeep([...this.availableTimeSlots])

      data.availableTimeSlots = [...timeslots]

      const currentUser = firebase.auth().currentUser
      const userRef = await this.$db.collection('users').doc(currentUser.uid)

      await userRef.set(data, { merge: true })

      this.$store.commit('UPDATE_USER_INFO', {
        availableTimeSlots: [...data.availableTimeSlots],
        closeAnimation: this.$vs.loading.close
      })

      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.changesSavedSuccessfully'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })
    },

    async addUpdateTimeRange() {
      if (this.timeRangeDialogType === 'system.add') {
        if (this.start !== null && this.end !== null) {
          this.availableTimeSlots[this.selectedDay].times.push({
            start: `${this.start.HH}:${this.start.mm}`,
            end: `${this.end.HH}:${this.end.mm}`
          })
        }
      }

      if (this.timeRangeDialogType === 'system.save') {
        if (this.start !== null && this.end !== null) {
          this.availableTimeSlots[this.selectedDay].times[this.selectedTime] = {
            start: `${this.start.HH}:${this.start.mm}`,
            end: `${this.end.HH}:${this.end.mm}`
          }
        }
      }

      await this.updateAvailableTimeSlots()

      this.start = {
        HH: '00',
        mm: '00'
      }

      this.end = {
        HH: '00',
        mm: '00'
      }

      this.selectedDay = null
      this.selectedTime = null
      this.timeRangeDialog = false
    },

    slotUpdated(slot) {
      if (!slot.enabled) {
        slot.times = []
      }
    },

    addTime(i) {
      this.selectedDay = i
      this.timeRangeDialog = true
      this.timeRangeDialogType = 'system.add'
    },

    editTime(d, t, time) {
      this.selectedDay = d
      this.selectedTime = t

      if (time.start && !time.start.HH && time.start.indexOf(':') > -1) {
        const start = {
          HH: time.start.split(':')[0],
          mm: time.start.split(':')[1]
        }

        this.start = start
      } else {
        this.start = time.start
      }

      if (time.end && !time.end.HH && time.end.indexOf(':') > -1) {
        const end = {
          HH: time.end.split(':')[0],
          mm: time.end.split(':')[1]
        }

        this.end = end
      } else {
        this.end = time.end
      }

      this.timeRangeDialog = true
      this.timeRangeDialogType = 'system.save'
    },

    async deleteTime(i, slot) {
      slot.times.splice(i, 1)

      await this.updateAvailableTimeSlots()
    },

    format12HrTime(time24hr) {
      return moment(time24hr, 'HH:mm').locale(this.$i18n.locale).format('LT')
    },

    async getCalendars(calendars) {
      this.isLoadingCalendarIdsMultiselect = true
      this.calendarIdsListOptions = []

      this.$vs.loading()

      try {
        let data = calendars

        if (!calendars) {
          const calendarGetCalendars = this.$functions.httpsCallable('calendarGetCalendars')
          const response = await calendarGetCalendars({ userId: this.activeUserInfo.uid })

          if (response && response.data && response.data.length >= 0) {
            data = response.data

            this.$forceUpdate()
          }
        }

        this.syncedEmail = this.activeUserInfo.syncedEmail

        if (data && data.length) {
          this.calendarIdsListOptions = data.sort((a, b) => b.isPrimary - !!a.isPrimary)
        }

        this.getSyncedCalendarId()
      } catch (error) {
        this.catchErrorForSyncCalendar()
      }

      this.isLoadingCalendarIdsMultiselect = false

      this.$vs.loading.close()
    },

    async syncCalendar(type) {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        this.$vs.loading()

        try {
          const calendarAuthorizeUrl = this.$functions.httpsCallable('calendarAuthorizeUrl')
          const { data: url } = await calendarAuthorizeUrl({ type })

          window.location.href = url
        } catch {
          this.catchErrorForSyncCalendar()
        }
      }
    },

    catchErrorForSyncCalendar() {
      this.$toast.error(this.$t('error.C_101'))

      this.$vs.loading.close()
    },

    modifyDayNameToLong(value) {
      if (!value || !(typeof value === 'string' || value instanceof String)) {
        return value
      }

      return value.replace('short', 'long')
    }
  }
}
</script>

<style lang="scss">
.calendar-sync-wrapper {
  margin-top: 30px;
  border: 1px solid rgba(214, 214, 214, 1);
  border-radius: 6px;

  .calendar-sync {
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid rgba(214, 214, 214, 1);

      &:last-child {
        border-bottom: none;
      }
    }

    &__text {
      &-title {
        color: rgba(38, 38, 41, 1);
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
      }

      &-info {
        margin-top: 4px;
        color: rgb(115, 115, 115);
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__type {
      display: flex;
      align-items: center;

      &__info {
        margin-left: 20px;

        &__title {
          display: flex;
        }
      }
    }

    &__deatils {
      &__item {
        &__info {
          padding-right: 20px;
          max-width: 400px;
        }

        .multiselect {
          max-width: 470px;

          .multiselect__option {
            &--highlight {
              color: inherit !important;
              background: rgb(232, 232, 232) !important;
            }
          }
        }
      }
    }
  }
}
.calendar-sync-container {
  padding: 20px;
}
.custom-popper {
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  font-size: 12px !important;
  line-height: 14.4px !important;
  font-weight: 500 !important;
  background: rgb(48, 52, 247);
  border: none !important;
  box-shadow: none !important;
  border-radius: 6px;

  &[x-placement^='top'] .popper__arrow {
    border-width: 5px 5px 1px 5px !important;
    border-color: rgb(48, 52, 247) transparent transparent transparent !important;
  }
}

.disabled-button {
  color: #c4c4c4;
}
.blur-background {
  filter: blur(2px);
}
.availability-container {
  min-height: 300px;
}
.vs-tooltip {
  background: rgba(var(--vs-secondary), 1) !important;
  z-index: 100000;
}

.modal-container {
  @media screen and (max-width: 767px) {
    max-width: calc(100% - 40px) !important;
  }
}

.availability {
  &__heading {
    margin: 0 auto;
    max-width: 760px;
    text-align: center;
    color: #262629;

    &__title {
      color: #262629;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
    }

    &__description {
      margin-top: 15px;
      font-size: 16px;
    }
  }

  &__title-inner {
    color: rgb(18, 89, 141);
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
  }

  &-hints {
    &__info {
      position: relative;
      padding-left: 31px;

      &__icon {
        position: absolute;
        top: -1px;
        left: 0;
      }
    }
  }

  &__popper-wrapper {
    .custom-popper {
      max-width: calc(100% - 10px);
    }
  }
}

.options-list {
  padding-left: 13px;

  &__item {
    position: relative;
    padding-left: 9px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: -3px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: rgb(87, 87, 87);
    }
  }
}

.vue__time-picker {
  .dropdown {
    ul {
      li:not([disabled]) {
        &.active,
        &.active:focus,
        &.active:hover {
          color: rgb(255, 255, 255);
          background: rgba(var(--vs-primary), 1);
        }
      }
    }
  }
}

.vs-popup {
  width: 460px !important;
}

.availability-container {
  .vs-popup {
    max-width: 400px !important;

    @media (max-width: 480px) {
      max-width: calc(100% - 40px) !important;
    }
  }
}

.vue__time-picker {
  input.display-time {
    width: 100%;
  }
}

.btn {
  &-main {
    font-size: 20px;
  }

  &-add {
    &__label {
      position: relative;
      margin-left: 35px;
      margin-right: 35px;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        height: 1.5px;
        background: rgb(255, 255, 255);
      }

      &::before {
        left: -22px;
        width: 13px;
      }

      &::after {
        left: -21px;
        width: 12px;
        transform: rotate(90deg);
      }
    }
  }
}

.checkbox-main {
  font-size: 18px;

  .vs-checkbox {
    margin-right: 20px;
    width: 22px;
    height: 22px;
  }
}

.slot {
  &-wrapper {
    margin-bottom: 30px;
  }

  &-details {
    @media screen and (min-width: 768px) {
      margin-left: 48px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background: rgba(230, 236, 241, 0.5);
    border-radius: 6px;

    &__time {
      width: 175px;
    }

    &__actions {
      display: flex;

      & > * {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.service {
  &-list-instruction {
    text-align: center;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 4px 0 4px 4px;

    &__link {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      padding: 6px;
      width: 100%;
      height: 100%;
      color: rgb(39, 39, 39);
      text-decoration: none;
    }

    &__item {
      flex: 0 0 120px;
      margin: 10px 15px 10px 15px;
      width: 120px;
      height: 120px;
      color: rgb(39, 39, 39);
      font-size: 14px;
      border: 1px solid rgb(150, 150, 150);
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: rgb(245, 245, 247);
      }
    }

    &__img {
      width: 50px;
      height: 50px;
    }

    &__text {
      width: 100%;
      text-align: center;
      word-break: break-word;
    }
  }

  &-current-calendar {
    width: 35px;
  }
}
</style>

<template>
  <div>
    <vx-card v-if="showCongratulationsBanner" class="text-center bg-primary-gradient greet-user">
      <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200" />
      <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175" />
      <feather-icon icon="AwardIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
      <h1 class="mb-6 text-white">{{ $t('vue.congratulations') }}</h1>
      <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">{{ $t('vue.setPasswordMessage') }}</p>
    </vx-card>
    <vx-card no-shadow>
      <form ref="form">
        <vs-input
          data-vv-as="Password"
          name="password"
          ref="password"
          type="password"
          class="w-full mb-2"
          :label-placeholder="$t('inputs.password')"
          v-model="password"
          v-validate="'required|min:6|max:128|verifyPassword'"
        />
        <span class="text-danger text-sm">{{ errors.first('password') }}</span>
        <vs-input
          data-vv-as="Confirm password"
          name="confirm_password"
          class="w-full mb-2"
          type="password"
          :label-placeholder="$t('inputs.confirmPassword')"
          v-model="confirm_password"
          v-validate="'required|min:6|max:128|confirmed:password|verifyPassword'"
        />
        <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>
        <!-- Save & Reset Button -->
        <div class="flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click.prevent="savePassword">{{ $t('vue.saveChanges') }}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click.prevent="reset">{{ $t('vue.reset') }}</vs-button>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {
      password: '',
      confirm_password: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    showCongratulationsBanner() {
      return this.activeUserInfo && !this.activeUserInfo.displayedSetPassword && !this.activeUserInfo.provider
    }
  },
  async beforeDestroy() {
    if (this.activeUserInfo && this.activeUserInfo.uid) {
      const userInfo = {
        displayedSetPassword: true
      }
      const userRef = await this.$db.collection('users').doc(this.activeUserInfo.uid)
      await userRef.set(userInfo, { merge: true })
      this.$store.commit('UPDATE_USER_INFO', { displayedSetPassword: true })
    }
  },
  methods: {
    reset() {
      this.password = ''
      this.confirm_password = ''
      setTimeout(() => this.errors.clear(), 50)
    },
    async savePassword() {
      const vm = this
      const validate = await this.$validator.validateAll()
      if (validate) {
        this.$vs.loading()
        const user = firebase.auth().currentUser
        await user
          .updatePassword(this.password)
          .then(async () => {
            const userInfo = {
              hasSetPassword: true,
              displayedSetPassword: true
            }
            const userRef = await vm.$db.collection('users').doc(user.uid)
            await userRef.set(userInfo, { merge: true })
            vm.$store.commit('UPDATE_USER_INFO', userInfo)
            this.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.success'),
              text: vm.$i18n.t('vue.passwordUpdatedSuccessfully'),
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success'
            })
            this.$serverBus.$emit('set-password-successfully')
            this.reset()
          })
          .catch((error) => {
            this.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.error'),
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        this.$vs.loading.close()
      }
    }
  }
}
</script>

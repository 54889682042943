<template>
  <vx-card no-shadow>
    <form ref="form">
      <div v-if="isMobile" class="flex items-center">
        <span class="user-settings__title-inner">{{ $t('vue.changePassword') }}</span>
      </div>

      <vs-input
        data-vv-as="Old password"
        name="old_password"
        class="w-full mb-2"
        type="password"
        :label-placeholder="$t('inputs.oldPassword')"
        v-model="old_password"
        v-validate="'required'"
      />
      <span class="text-danger text-sm">{{ errors.first('old_password') }}</span>
      <vs-input
        data-vv-as="New password"
        name="new_password"
        ref="new_password"
        type="password"
        class="w-full mb-2"
        :label-placeholder="$t('inputs.newPassword')"
        v-model="new_password"
        v-validate="'required|min:6|max:128|verifyPassword'"
      />
      <span class="text-danger text-sm">{{ errors.first('new_password') }}</span>
      <vs-input
        data-vv-as="Confirm password"
        name="confirm_new_password"
        class="w-full mb-2"
        type="password"
        :label-placeholder="$t('inputs.confirmPassword')"
        v-model="confirm_new_password"
        v-validate="'required|min:6|max:128|confirmed:new_password|verifyPassword'"
      />
      <span class="text-danger text-sm">{{ errors.first('confirm_new_password') }}</span>

      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button class="ml-auto mt-2" @click.prevent="savePassword">{{ $t('vue.saveChanges') }}</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="warning" @click.prevent="reset">{{ $t('vue.reset') }}</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    reset() {
      this.old_password = ''
      this.new_password = ''
      this.confirm_new_password = ''
      setTimeout(() => this.errors.clear(), 50)
    },
    async reauthenticate(currentPassword) {
      const user = firebase.auth().currentUser
      const cred = await firebase.auth.EmailAuthProvider.credential(user.email, currentPassword)

      let result = {}
      await user
        .reauthenticateWithCredential(cred)
        .then(() => {
          result.code = 'success'
        })
        .catch((err) => {
          result = err
        })

      return result
    },
    async savePassword() {
      const vm = this
      const validate = await this.$validator.validateAll()
      if (validate) {
        this.$vs.loading()
        const result = await this.reauthenticate(this.old_password)
        if (result.code === 'success') {
          const user = firebase.auth().currentUser
          await user
            .updatePassword(this.new_password)
            .then(() => {
              this.$vs.notify({
                time: 8800,
                title: vm.$i18n.t('vue.success'),
                text: vm.$i18n.t('vue.passwordUpdatedSuccessfully'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
              this.reset()
            })
            .catch((error) => {
              this.$vs.notify({
                time: 8800,
                title: vm.$i18n.t('vue.error'),
                text: error.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
        } else if (result.code === 'auth/wrong-password') {
          this.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.error'),
            text: vm.$i18n.t('vue.incorrectPasswordMessage'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        } else {
          this.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.error'),
            text: result.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }

        this.$vs.loading.close()
      }
    }
  }
}
</script>

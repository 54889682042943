var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", { attrs: { "no-shadow": "" } }, [
    _c(
      "form",
      { ref: "form" },
      [
        _vm.isMobile
          ? _c("div", { staticClass: "flex items-center" }, [
              _c("span", { staticClass: "user-settings__title-inner" }, [
                _vm._v(_vm._s(_vm.$t("vue.changePassword"))),
              ]),
            ])
          : _vm._e(),
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'",
            },
          ],
          staticClass: "w-full mb-2",
          attrs: {
            "data-vv-as": "Old password",
            name: "old_password",
            type: "password",
            "label-placeholder": _vm.$t("inputs.oldPassword"),
          },
          model: {
            value: _vm.old_password,
            callback: function ($$v) {
              _vm.old_password = $$v
            },
            expression: "old_password",
          },
        }),
        _c("span", { staticClass: "text-danger text-sm" }, [
          _vm._v(_vm._s(_vm.errors.first("old_password"))),
        ]),
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:6|max:128|verifyPassword",
              expression: "'required|min:6|max:128|verifyPassword'",
            },
          ],
          ref: "new_password",
          staticClass: "w-full mb-2",
          attrs: {
            "data-vv-as": "New password",
            name: "new_password",
            type: "password",
            "label-placeholder": _vm.$t("inputs.newPassword"),
          },
          model: {
            value: _vm.new_password,
            callback: function ($$v) {
              _vm.new_password = $$v
            },
            expression: "new_password",
          },
        }),
        _c("span", { staticClass: "text-danger text-sm" }, [
          _vm._v(_vm._s(_vm.errors.first("new_password"))),
        ]),
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value:
                "required|min:6|max:128|confirmed:new_password|verifyPassword",
              expression:
                "'required|min:6|max:128|confirmed:new_password|verifyPassword'",
            },
          ],
          staticClass: "w-full mb-2",
          attrs: {
            "data-vv-as": "Confirm password",
            name: "confirm_new_password",
            type: "password",
            "label-placeholder": _vm.$t("inputs.confirmPassword"),
          },
          model: {
            value: _vm.confirm_new_password,
            callback: function ($$v) {
              _vm.confirm_new_password = $$v
            },
            expression: "confirm_new_password",
          },
        }),
        _c("span", { staticClass: "text-danger text-sm" }, [
          _vm._v(_vm._s(_vm.errors.first("confirm_new_password"))),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "ml-auto mt-2",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.savePassword.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("vue.saveChanges")))]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-4 mt-2",
                attrs: { type: "border", color: "warning" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.reset.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("vue.reset")))]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
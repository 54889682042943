<template>
  <vx-card no-shadow>
    <form ref="form">
      <div class="flex flex-wrap justify-between items-center mb-8">
        <p v-html="DELETE_ACCOUNT_TEXT" class="mb-5" :key="messageUpdateKey"></p>
        <vs-button type="border" color="danger" @click="showDeletePrompt()">{{ $t('vue.deleteAccount') }}</vs-button>
      </div>
    </form>
    <vs-prompt
      :title="$t('vue.deleteAccount')"
      @cancel="onCloseModal"
      @accept="deleteAccount"
      @close="onCloseModal"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt">
        <p>
          <strong>{{ DELETE_ACCOUNT_TEXT }}</strong>
        </p>
        {{ $t('modals.deleteAccountQuestion') }}
      </div>
    </vs-prompt>
  </vx-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      messageUpdateKey: Math.random().toString(36).substring(2, 15),
      deletePrompt: false,
      showDeleteSubscriptionMessage: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company'
    }),
    DELETE_ACCOUNT_TEXT() {
      let result = ''
      if (!this.showDeleteSubscriptionMessage) {
        result = this.$i18n.t('vue.deleteAccountWarningMessage')
      } else {
        result = `${this.$i18n.t('vue.deleteAccountWarningMessage')} ${this.$i18n.t('vue.cancelSubscriptionMessage')}`
      }
      return result
    }
  },
  async mounted() {
    if (this.company && this.company.paymentStatus === 'subscription' && this.company.subscriptionId) {
      const users = await this.$db.collection('users').where('company', '==', this.activeUserInfo.company).where('isActive', '==', true).get()
      if (users.size === 1) {
        this.showDeleteSubscriptionMessage = true
        this.messageUpdateKey = Math.random().toString(36).substring(2, 15)
      }
    }
  },
  methods: {
    ...mapMutations({
      updateCompany: 'UPDATE_COMPANY'
    }),
    onCloseModal() {
      this.deletePrompt = false
    },
    async showDeletePrompt() {
      this.deletePrompt = true
    },

    async deleteAccount() {
      this.$vs.loading()
      //await firebase.functions().useFunctionsEmulator('http://localhost:5001')
      const deleteAccount = this.$functions.httpsCallable('deleteAccount')
      const result = await deleteAccount({ uid: this.activeUserInfo.uid, company: this.activeUserInfo.company })
      if (result.data.status === true) {
        this.$store.commit('REMOVE_USER_INFO', {
          closeAnimation: this.$vs.loading.close
        })

        this.updateCompany(null)
        window.location.href = '/pages/login'
      } else {
        const message = this.$i18n.t(`error.${result.data.messageCode}`)
        this.$vs.notify({
          time: 4000,
          title: this.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
        this.$vs.loading.close()
      }
    }
  }
}
</script>

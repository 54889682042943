var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    { attrs: { "no-shadow": "" } },
    [
      _c("form", { ref: "form" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-between items-center mb-8" },
          [
            _c("p", {
              key: _vm.messageUpdateKey,
              staticClass: "mb-5",
              domProps: { innerHTML: _vm._s(_vm.DELETE_ACCOUNT_TEXT) },
            }),
            _c(
              "vs-button",
              {
                attrs: { type: "border", color: "danger" },
                on: {
                  click: function ($event) {
                    return _vm.showDeletePrompt()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("vue.deleteAccount")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("vue.deleteAccount"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.deletePrompt,
          },
          on: {
            cancel: _vm.onCloseModal,
            accept: _vm.deleteAccount,
            close: _vm.onCloseModal,
            "update:active": function ($event) {
              _vm.deletePrompt = $event
            },
          },
        },
        [
          _c("div", { staticClass: "con-exemple-prompt" }, [
            _c("p", [_c("strong", [_vm._v(_vm._s(_vm.DELETE_ACCOUNT_TEXT))])]),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("modals.deleteAccountQuestion")) +
                "\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "availability--mobile": _vm.isMobile } },
    [
      !_vm.isMobile
        ? _c("div", { staticClass: "vx-row mb-10" }, [
            _c("div", { staticClass: "mt-10 vx-col availability__heading" }, [
              _c("h3", { staticClass: "availability__heading__title" }, [
                _vm._v(_vm._s(_vm.$t("views.availability.title"))),
              ]),
              _c("p", { staticClass: "availability__heading__description" }, [
                _vm._v(_vm._s(_vm.$t("views.availability.description"))),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "availability-container relative" },
        [
          _c(
            "div",
            { class: !_vm.HAS_ACCESS ? "blur-background" : "" },
            [
              !_vm.isMobile
                ? _c(
                    "vx-card",
                    { staticClass: "mb-3", attrs: { "no-shadow": "" } },
                    [
                      !!(
                        _vm.activeUserInfo.isSyncedCalendar &&
                        _vm.activeUserInfo.syncedCalendarType
                      )
                        ? _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 availability__title-inner",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("calendar.calendarSynchronization")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "vx-tooltip",
                                {
                                  staticClass: "flex items-center",
                                  staticStyle: { width: "17px" },
                                  attrs: {
                                    position: "top",
                                    title: _vm.$t(
                                      "views.availability.calendarSynchronization.main"
                                    ),
                                    text:
                                      _vm.$t(
                                        "views.availability.calendarSynchronization.option_1"
                                      ) +
                                      " " +
                                      _vm.$t(
                                        "views.availability.calendarSynchronization.option_2"
                                      ) +
                                      " " +
                                      _vm.$t(
                                        "views.availability.calendarSynchronization.option_3"
                                      ),
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { display: "inline-block" },
                                    attrs: {
                                      width: "17",
                                      height: "17",
                                      src: _vm.infoImg,
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !(
                        _vm.activeUserInfo.isSyncedCalendar &&
                        _vm.activeUserInfo.syncedCalendarType
                      )
                        ? _c(
                            "div",
                            { staticClass: "availability-hints__info" },
                            [
                              _c("vs-icon", {
                                staticClass: "availability-hints__info__icon",
                                attrs: {
                                  "icon-pack": "material-icons",
                                  icon: "error",
                                  size: "22px",
                                  color: "rgb(240, 85, 65)",
                                },
                              }),
                              _vm._v(
                                "\n\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.availability.calendarSynchronization.main"
                                    )
                                  ) +
                                  "\n\n          "
                              ),
                              _c("ul", { staticClass: "options-list" }, [
                                _c(
                                  "li",
                                  { staticClass: "options-list__item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.availability.calendarSynchronization.option_1"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  { staticClass: "options-list__item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.availability.calendarSynchronization.option_2"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  { staticClass: "options-list__item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.availability.calendarSynchronization.option_3"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "mt-5 vx-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col pl-5" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "mr-3 btn-main",
                                        attrs: {
                                          size: "medium",
                                          color: "primary",
                                          disabled: !_vm.HAS_ACCESS,
                                        },
                                        on: {
                                          click: _vm.showSyncCalendarDialog,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("calendar.syncCalendar")
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "calendar-sync-wrapper" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "calendar-sync__row calendar-sync__heading",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "calendar-sync__type" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "calendar-sync__type__img",
                                      },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "service-current-calendar",
                                          attrs: {
                                            src: require("@/assets/images/calendars/" +
                                              _vm.activeUserInfo
                                                .syncedCalendarType +
                                              ".png"),
                                            alt: _vm.$t("calendar.name"),
                                            title: _vm.$t("calendar.name"),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "calendar-sync__type__info",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "calendar-sync__text-title calendar-sync__type__info__title",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.activeUserInfo.syncedCalendarType.includes(
                                                    "google"
                                                  )
                                                    ? "Google"
                                                    : "Outlook"
                                                ) +
                                                "\n\n                  "
                                            ),
                                            _c("img", {
                                              staticClass: "ml-3",
                                              attrs: {
                                                src: require("@/assets/images/pages/verified-check.svg"),
                                                alt: "verified",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "calendar-sync__text-info",
                                          },
                                          [_vm._v(_vm._s(_vm.syncedEmail))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex calendar-sync__buttons-wrapper",
                                  },
                                  [
                                    _c("vs-button", {
                                      staticClass: "mb-base mr-3",
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        icon: "refresh",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.getCalendars()
                                        },
                                      },
                                    }),
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "inline-flex",
                                        attrs: {
                                          text: _vm.$t("vue.delete"),
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c("vs-button", {
                                          attrs: {
                                            color: "#F05541",
                                            type: "border",
                                            size: "medium",
                                            "icon-pack": "feather",
                                            icon: "icon-trash",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.cancelSync = true
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "calendar-sync__deatils" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "calendar-sync__row calendar-sync__deatils__item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "calendar-sync__deatils__item__info",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "calendar-sync__text-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "calendar.chooseYourCalendar"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "calendar-sync__text-info",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "calendar.chooseYourCalendarDescription"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    !_vm.isLoadingCalendarIdsMultiselect
                                      ? _c("multiselect", {
                                          ref: "calendarIdsList",
                                          attrs: {
                                            options: _vm.calendarIdsListOptions,
                                            multiple: false,
                                            "close-on-select": true,
                                            "clear-on-select": false,
                                            placeholder: "",
                                            selectLabel: "",
                                            selectedLabel: "",
                                            deselectLabel: "",
                                            label: "name",
                                            "track-by": "id",
                                            "open-direction": "bottom",
                                            "data-vv-as": "name",
                                            "preserve-search": true,
                                            "preselect-first": false,
                                            "allow-empty": false,
                                          },
                                          on: {
                                            select:
                                              _vm.updateSelectedSyncedCalendar,
                                          },
                                          model: {
                                            value: _vm.syncedCalendar,
                                            callback: function ($$v) {
                                              _vm.syncedCalendar = $$v
                                            },
                                            expression: "syncedCalendar",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "calendar-sync__row calendar-sync__deatils__item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "calendar-sync__deatils__item__info",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "calendar-sync__text-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "calendar.onlineMeetingTool"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "calendar-sync__text-info",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "calendar.onlineMeetingToolDescription"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.onlineMeetingToolsFiltered &&
                                    _vm.onlineMeetingToolsFiltered.length > 0
                                      ? _c("multiselect", {
                                          ref: "onlineMeetingTools",
                                          attrs: {
                                            options:
                                              _vm.onlineMeetingToolsFiltered,
                                            multiple: false,
                                            "close-on-select": true,
                                            "clear-on-select": false,
                                            placeholder: "",
                                            selectLabel: "",
                                            selectedLabel: "",
                                            deselectLabel: "",
                                            label: "name",
                                            "track-by": "id",
                                            "open-direction": "bottom",
                                            "data-vv-as": "name",
                                            "preserve-search": true,
                                            "preselect-first": false,
                                            "allow-empty": false,
                                          },
                                          on: {
                                            select:
                                              _vm.updateSelectedOnlineMeetingTool,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "singleLabel",
                                                fn: function (ref) {
                                                  var option = ref.option
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex items-center",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass: "mr-3",
                                                          staticStyle: {
                                                            "object-fit":
                                                              "contain",
                                                          },
                                                          attrs: {
                                                            width: "20",
                                                            height: "20",
                                                            src: require("@/assets/images/calendars/" +
                                                              option.img),
                                                            alt: _vm.$t(
                                                              "calendar.name"
                                                            ),
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              option.name
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "option",
                                                fn: function (ref) {
                                                  var option = ref.option
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex items-center",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass: "mr-3",
                                                          staticStyle: {
                                                            "object-fit":
                                                              "contain",
                                                          },
                                                          attrs: {
                                                            width: "20",
                                                            height: "20",
                                                            src: require("@/assets/images/calendars/" +
                                                              option.img),
                                                            alt: _vm.$t(
                                                              "calendar.name"
                                                            ),
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              option.name
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1197681398
                                          ),
                                          model: {
                                            value: _vm.onlineMeetingTool,
                                            callback: function ($$v) {
                                              _vm.onlineMeetingTool = $$v
                                            },
                                            expression: "onlineMeetingTool",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "vx-card",
                { attrs: { "no-shadow": "" } },
                [
                  _c(
                    "connect-modal",
                    {
                      attrs: {
                        show: _vm.timeRangeDialog,
                        width: "460px",
                        height: "300px",
                        bkcolor: "#F7F7F7",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex flex-row visitor-list-delete-all",
                        },
                        [
                          _c("div", [
                            _c("h3", [
                              _vm._v(_vm._s(_vm.$t("inputs.selectTimeRange"))),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "auto",
                                cursor: "pointer",
                              },
                              attrs: { disabled: "" },
                              on: {
                                click: function ($event) {
                                  _vm.timeRangeDialog = false
                                },
                              },
                            },
                            [
                              _c("close-circle-icon", {
                                staticClass: "pa-bk-secondary",
                                attrs: {
                                  "show-circle": false,
                                  width: 14,
                                  height: 14,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "flex flex-wrap justify-center",
                          staticStyle: { height: "200px" },
                        },
                        [
                          _c(
                            "vs-row",
                            { attrs: { w: "12" } },
                            [
                              _c("vs-col", { attrs: { w: "12" } }, [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v(_vm._s(_vm.$t("inputs.start"))),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("vue-timepicker", {
                            staticClass: "w-full mb-base",
                            attrs: {
                              "close-on-complete": "",
                              format: "HH:mm",
                              "minute-interval": _vm.minuteInterval,
                              "fixed-dropdown-button": "",
                              "drop-direction": "down",
                            },
                            on: { change: _vm.timeChange },
                            model: {
                              value: _vm.start,
                              callback: function ($$v) {
                                _vm.start = $$v
                              },
                              expression: "start",
                            },
                          }),
                          _c(
                            "vs-row",
                            { attrs: { w: "12" } },
                            [
                              _c("vs-col", { attrs: { w: "12" } }, [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v(_vm._s(_vm.$t("inputs.end"))),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("vue-timepicker", {
                            staticClass: "w-full mb-base",
                            attrs: {
                              "close-on-complete": "",
                              "drop-direction": "up",
                              "fixed-dropdown-button": "",
                              "hide-disabled-items": "",
                              format: "HH:mm",
                              "hour-range": _vm.hourRange,
                              "minute-range": _vm.minuteRange,
                              "minute-interval": _vm.minuteInterval,
                            },
                            on: {
                              change: _vm.timeChange,
                              error: _vm.errorHanlder,
                            },
                            model: {
                              value: _vm.end,
                              callback: function ($$v) {
                                _vm.end = $$v
                              },
                              expression: "end",
                            },
                          }),
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-full mb-base",
                              attrs: {
                                color: "primary",
                                type: "filled",
                                icon: "add",
                                disabled: _vm.hasError || !_vm.HAS_ACCESS,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addUpdateTimeRange.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("vue.addTimeRange")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "connect-modal",
                    {
                      attrs: {
                        show: _vm.syncCalendarDialog,
                        width: "460px",
                        height: "300px",
                        bkcolor: "#F7F7F7",
                      },
                    },
                    [
                      _c("div", { staticClass: "calendar-sync-container" }, [
                        _c("div", { staticClass: "flex flex-row" }, [
                          _c("div", [
                            _c("h3", [
                              _vm._v(_vm._s(_vm.$t("calendar.syncMyCalendar"))),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "auto",
                                cursor: "pointer",
                              },
                              attrs: { disabled: "" },
                              on: {
                                click: function ($event) {
                                  _vm.syncCalendarDialog = false
                                },
                              },
                            },
                            [
                              _c("close-circle-icon", {
                                staticClass: "pa-bk-secondary",
                                attrs: {
                                  "show-circle": false,
                                  width: 14,
                                  height: 14,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-4 service-list-instruction" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("calendar.chooseAccount")) + ":"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "service-list" },
                          _vm._l(_vm.calendarList, function (calendar) {
                            return _c(
                              "div",
                              {
                                key: calendar.type,
                                staticClass: "service-list__item",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "service-list__link",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.syncCalendar(calendar.type)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "service-list__img",
                                      attrs: {
                                        src: require("@/assets/images/calendars/" +
                                          calendar.type +
                                          ".png"),
                                        alt:
                                          calendar.title +
                                          " " +
                                          _vm.$t("calendar.name"),
                                        title:
                                          calendar.title +
                                          " " +
                                          _vm.$t("calendar.name"),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "service-list__text" },
                                      [
                                        _vm._v(
                                          _vm._s(calendar.title) +
                                            " " +
                                            _vm._s(_vm.$t("calendar.name"))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _c("form", { ref: "form" }, [
                    !_vm.isMobile
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "mt-5 flex flex-wrap items-center mb-base",
                          },
                          [
                            _c("multiselect", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              ref: "timezones",
                              attrs: {
                                options: _vm.timezones,
                                multiple: false,
                                "close-on-select": true,
                                "clear-on-select": false,
                                placeholder: "",
                                selectLabel: "Select timezone",
                                selectedLabel: "",
                                deselectLabel: "",
                                label: "name",
                                "track-by": "name",
                                "open-direction": "bottom",
                                "data-vv-as": "timezone",
                                "preserve-search": true,
                                "preselect-first": false,
                              },
                              model: {
                                value: _vm.activeUserInfo.timezone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeUserInfo, "timezone", $$v)
                                },
                                expression: "activeUserInfo.timezone",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "user-settings__title-inner availability__title-inner",
                            class: {
                              "mr-2": !_vm.isMobile,
                              "mr-3": _vm.isMobile,
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("vue.availability")))]
                        ),
                        !_vm.isMobile &&
                        _vm.activeUserInfo.availableTimeSlots &&
                        !!_vm.activeUserInfo.availableTimeSlots.find(function (
                          slot
                        ) {
                          return slot.enabled
                        })
                          ? _c(
                              "vx-tooltip",
                              {
                                staticClass: "flex items-center",
                                staticStyle: { width: "17px" },
                                attrs: {
                                  position: "top",
                                  text: _vm.$t(
                                    "views.availability.availability.main"
                                  ),
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    width: "17",
                                    height: "17",
                                    src: _vm.infoImg,
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.isMobile
                          ? _c(
                              "popper",
                              {
                                attrs: {
                                  trigger: "clickToToggle",
                                  "append-to-body": "true",
                                  "root-class": "availability__popper-wrapper",
                                  options: {
                                    placement: "bottom",
                                    modifiers: { offset: { offset: "0,10px" } },
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "popper custom-popper" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.availability.availability.main"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "flex",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { display: "inline-block" },
                                      attrs: {
                                        width: "17",
                                        height: "17",
                                        src: _vm.infoImg,
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.activeUserInfo.availableTimeSlots &&
                    !_vm.activeUserInfo.availableTimeSlots.find(function (
                      slot
                    ) {
                      return slot.enabled
                    })
                      ? _c(
                          "div",
                          { staticClass: "availability-hints__info" },
                          [
                            _c("vs-icon", {
                              staticClass: "availability-hints__info__icon",
                              attrs: {
                                "icon-pack": "material-icons",
                                icon: "error",
                                size: "22px",
                                color: "rgb(240, 85, 65)",
                              },
                            }),
                            _vm._v(
                              "\n\n            " +
                                _vm._s(
                                  _vm.$t("views.availability.availability.main")
                                ) +
                                "\n          "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "mt-5 flex flex-wrap items-center mb-base",
                      },
                      _vm._l(_vm.availableTimeSlots, function (slot, d) {
                        return _c("vs-row", { key: d }, [
                          _c("div", { staticClass: "vx-row slot-wrapper" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full" },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    staticClass: "ma-0 pt-0 pb-1 checkbox-main",
                                    attrs: { "hide-details": "" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.slotUpdated(slot, d)
                                      },
                                    },
                                    model: {
                                      value: slot.enabled,
                                      callback: function ($$v) {
                                        _vm.$set(slot, "enabled", $$v)
                                      },
                                      expression: "slot.enabled",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            _vm.modifyDayNameToLong(slot.day)
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                slot.enabled
                                  ? _c(
                                      "div",
                                      { staticClass: "slot-details" },
                                      [
                                        _vm._l(slot.times, function (time, t) {
                                          return _c(
                                            "div",
                                            {
                                              key: t,
                                              staticClass: "slot-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "slot-item__time",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.format12HrTime(
                                                        time.start
                                                      )
                                                    ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.format12HrTime(
                                                          time.end
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "slot-item__actions",
                                                },
                                                [
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      staticClass:
                                                        "inline-flex",
                                                      attrs: {
                                                        text: _vm.$t(
                                                          "vue.edit"
                                                        ),
                                                        position: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.HAS_ACCESS,
                                                          color: !_vm.HAS_ACCESS
                                                            ? "#c4c4c4"
                                                            : "#3B86F7",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-edit",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.editTime(
                                                              d,
                                                              t,
                                                              time
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      staticClass:
                                                        "inline-flex",
                                                      attrs: {
                                                        text: _vm.$t(
                                                          "vue.delete"
                                                        ),
                                                        position: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.HAS_ACCESS,
                                                          color: !_vm.HAS_ACCESS
                                                            ? "#c4c4c4"
                                                            : "#F05541",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-trash",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.deleteTime(
                                                              t,
                                                              slot
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }),
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "btn-main btn-add",
                                            attrs: {
                                              size: "medium",
                                              color: "primary",
                                              disabled: !_vm.HAS_ACCESS,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addTime(d)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "btn-add__label" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("inputs.add"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ])
                      }),
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.HAS_ACCESS ? _c("availability-message") : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("vue.cancelSynchronization"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.confirm"),
            active: _vm.cancelSync,
          },
          on: {
            cancel: function ($event) {
              _vm.cancelSync = false
            },
            accept: _vm.cancelSyncedCalendar,
            close: function ($event) {
              _vm.cancelSync = false
            },
            "update:active": function ($event) {
              _vm.cancelSync = $event
            },
          },
        },
        [
          _c("div", { staticClass: "con-exemple-prompt" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("vue.cancelSynchronizationMessage")) +
                "\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
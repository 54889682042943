var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showCongratulationsBanner
        ? _c(
            "vx-card",
            { staticClass: "text-center bg-primary-gradient greet-user" },
            [
              _c("img", {
                staticClass: "decore-left",
                attrs: {
                  src: require("@/assets/images/elements/decore-left.png"),
                  alt: "Decore Left",
                  width: "200",
                },
              }),
              _c("img", {
                staticClass: "decore-right",
                attrs: {
                  src: require("@/assets/images/elements/decore-right.png"),
                  alt: "Decore Right",
                  width: "175",
                },
              }),
              _c("feather-icon", {
                staticClass:
                  "p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow",
                attrs: { icon: "AwardIcon", svgClasses: "h-8 w-8" },
              }),
              _c("h1", { staticClass: "mb-6 text-white" }, [
                _vm._v(_vm._s(_vm.$t("vue.congratulations"))),
              ]),
              _c(
                "p",
                {
                  staticClass:
                    "xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white",
                },
                [_vm._v(_vm._s(_vm.$t("vue.setPasswordMessage")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("vx-card", { attrs: { "no-shadow": "" } }, [
        _c(
          "form",
          { ref: "form" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:6|max:128|verifyPassword",
                  expression: "'required|min:6|max:128|verifyPassword'",
                },
              ],
              ref: "password",
              staticClass: "w-full mb-2",
              attrs: {
                "data-vv-as": "Password",
                name: "password",
                type: "password",
                "label-placeholder": _vm.$t("inputs.password"),
              },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(_vm._s(_vm.errors.first("password"))),
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value:
                    "required|min:6|max:128|confirmed:password|verifyPassword",
                  expression:
                    "'required|min:6|max:128|confirmed:password|verifyPassword'",
                },
              ],
              staticClass: "w-full mb-2",
              attrs: {
                "data-vv-as": "Confirm password",
                name: "confirm_password",
                type: "password",
                "label-placeholder": _vm.$t("inputs.confirmPassword"),
              },
              model: {
                value: _vm.confirm_password,
                callback: function ($$v) {
                  _vm.confirm_password = $$v
                },
                expression: "confirm_password",
              },
            }),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(_vm._s(_vm.errors.first("confirm_password"))),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-wrap items-center justify-end" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-auto mt-2",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.savePassword.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("vue.saveChanges")))]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-4 mt-2",
                    attrs: { type: "border", color: "warning" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.reset.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("vue.reset")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <vx-card no-shadow>
    <form ref="form">
      <div v-if="isMobile" class="flex items-center">
        <span class="user-settings__title-inner">{{ $t('vue.changeEmail') }}</span>
      </div>

      <vs-input class="w-full" :label-placeholder="$t('inputs.email')" name="email" v-model="newEmail" v-validate="'required|validateEmail'"></vs-input>
      <span class="text-danger text-sm">{{ errors.first('email') }}</span>

      <div v-if="CAN_CHANGE_EMAIL && !errors.first('email')" style="display: flex; flex-direction: column" class="mt-2">
        <div class="otp-label">{{ $t('inputs.verificationCode') }}</div>
        <otp-input
          :label-placeholder="$t('inputs.email')"
          ref="otpInput"
          input-classes="otp-input"
          separator=" "
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
      </div>

      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button
          v-if="CAN_GENERATE_VERIFICATION_CODE"
          class="ml-auto mt-2"
          @click.prevent="generateVerificationCode('generate-verification-code')"
          :disabled="(errors.first('email') && errors.first('email').length > 0) || newEmail.length === 0"
        >
          {{ $t('vue.generateVerificationCode') }}
        </vs-button>

        <vs-button
          v-if="CAN_CHANGE_EMAIL"
          class="ml-4 mt-2"
          @click.prevent="updateEmail"
          :disabled="(errors.first('email') && errors.first('email').length > 0) || newEmail.length === 0 || !canChangeEmailAddress"
        >
          {{ $t('vue.changeEmail') }}
        </vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="warning" @click.prevent="reset">{{ $t('vue.reset') }}</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
import { mapGetters } from 'vuex'
import OtpInput from '@bachdgvn/vue-otp-input'
export default {
  components: {
    OtpInput
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newEmail: '',
      showVerificationCodeInput: false,
      canChangeEmailAddress: false,
      canGenerateVerificationCode: true,
      verificationCode: ''
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    CAN_GENERATE_VERIFICATION_CODE() {
      return this.canGenerateVerificationCode
    },

    CAN_CHANGE_EMAIL() {
      return this.newEmail && this.newEmail.length > 0 && this.activeUserInfo && this.activeUserInfo.UPDATE_EMAIL_OTP && this.showVerificationCodeInput
    }
  },
  watch: {
    newEmail() {
      this.showVerificationCodeInput = false
      this.canChangeEmailAddress = false
      this.canGenerateVerificationCode = true
    }
  },
  methods: {
    handleOnComplete(value) {
      this.canChangeEmailAddress = true
      this.verificationCode = value
    },
    handleOnChange(value) {
      this.canChangeEmailAddress = false
      this.verificationCode = value
    },

    reset() {
      this.newEmail = ''
      this.showVerificationCodeInput = false
      this.canChangeEmailAddress = false
      this.canGenerateVerificationCode = true
      setTimeout(() => this.errors.clear(), 50)
    },

    async generateVerificationCode(type) {
      this.$vs.loading()
      const generateEmailVerificationCode = this.$functions.httpsCallable('generateEmailVerificationCode')
      const res = await generateEmailVerificationCode({ newEmail: this.newEmail, locale: this.$i18n.locale, type })

      this.$vs.loading.close()

      const isStatus = res && res.data && res.data.status
      if (!isStatus) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('error.C_101'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          position: 'bottom-center'
        })
        return
      }

      if (isStatus === 'success') {
        this.showVerificationCodeInput = true
        this.canGenerateVerificationCode = false

        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.verificationCodeSentSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
          position: 'bottom-center'
        })
      }

      if (isStatus === 'error') {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t(`error.${res.data.message}`),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          position: 'bottom-center'
        })
      }
    },

    async updateEmail() {
      this.$vs.loading()
      const updateUserEmailAddress = this.$functions.httpsCallable('updateUserEmailAddress')
      const res = await updateUserEmailAddress({ newEmail: this.newEmail, locale: this.$i18n.locale, verificationCode: this.verificationCode })

      this.$vs.loading.close()

      if (res && res.data && res.data.status && res.data.status === 'success') {
        this.reset()

        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.emailChangedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
          position: 'bottom-center'
        })

        setTimeout(() => {
          location.reload(true)
        }, 2000)
      }

      if (res && res.data && res.data.status && res.data.status === 'error') {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t(`error.${res.data.message}`),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          position: 'bottom-center'
        })
      }
    }
  }
}
</script>
<style lang="scss">
.otp-label {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.85rem;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

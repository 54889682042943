var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-settings-ringtone" },
    [
      !_vm.isMobile
        ? _c("div", { staticClass: "vx-row mb-10" }, [
            _c("div", { staticClass: "mt-10 vx-col ringtones__heading" }, [
              _c("h3", { staticClass: "ringtones__heading__title" }, [
                _vm._v(_vm._s(_vm.$t("views.ringtones.title"))),
              ]),
              _c("p", { staticClass: "ringtones__heading__description" }, [
                _vm._v(_vm._s(_vm.$t("views.ringtones.description"))),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.hasTotalRingtonesLimitExceeded
        ? _c("input", {
            attrs: { type: "file", id: "file-input", accept: ".mp3,.wav" },
            on: { change: _vm.handleFileUpload },
          })
        : _vm._e(),
      !_vm.hasTotalRingtonesLimitExceeded
        ? _c("div", [
            _c(
              "label",
              {
                staticClass: "custom-file-input",
                attrs: { for: "file-input" },
              },
              [_vm._v(" Choose File ")]
            ),
            _c("span", { staticClass: "file-chosen" }, [
              _vm._v(_vm._s(_vm.fileName || "No file chosen")),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "file-info" }, [
        _vm._v(_vm._s(_vm.$t("info.ringtoneValidation"))),
      ]),
      _c(
        "div",
        { staticClass: "ringtone-items-container mt-5" },
        _vm._l(_vm.ringtones, function (ringtone, i) {
          return _c("div", { key: i, staticClass: "ringtone-item" }, [
            !ringtone.isPathadviceDefault
              ? _c(
                  "div",
                  {
                    staticClass: "ringtone-item--delete-icon",
                    on: {
                      click: function ($event) {
                        return _vm.deleteRingtone(ringtone)
                      },
                    },
                  },
                  [_c("DeleteIcon", { attrs: { color: "#fff" } })],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "filename" }, [
              _vm._v(_vm._s(_vm.getFileNameFromURL(ringtone))),
            ]),
            _c(
              "div",
              { staticClass: "ringtone-item--volume" },
              [
                _c("VolumeIcon", { attrs: { color: "#fff", stroke: "#fff" } }),
                _c("vs-slider", {
                  attrs: {
                    ticks: "",
                    step: 1,
                    "step-decimals": true,
                    max: 100,
                    disabled: _vm.isProcessing,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.adjustRingtoneVolume(ringtone, i)
                    },
                  },
                  model: {
                    value: ringtone.loudness,
                    callback: function ($$v) {
                      _vm.$set(ringtone, "loudness", $$v)
                    },
                    expression: "ringtone.loudness",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ringtone-item--volume" },
              [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.toggleDefaultRingtone(ringtone)
                      },
                    },
                  },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        width: 24,
                        height: 24,
                        enabled: ringtone.isDefault,
                      },
                    }),
                  ],
                  1
                ),
                _c("vs-button", {
                  attrs: {
                    "icon-pack": "feather",
                    size: "medium",
                    icon: ringtone.isPlaying ? "icon-pause" : "icon-play",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.togglePlayPause(ringtone, i)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "audio",
              {
                staticClass: "audio-player",
                attrs: { id: "rt-audio-player-" + i, loop: "" },
              },
              [
                _c("source", {
                  attrs: { src: ringtone.url, type: "audio/mpeg" },
                }),
                _vm._v(
                  "\n        Your browser does not support the audio element.\n      "
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _c(
        "vs-button",
        {
          attrs: { color: "primary", disabled: _vm.isProcessing },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.saveRingtones.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("vue.save")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="user-settings-ringtone">
    <div v-if="!isMobile" class="vx-row mb-10">
      <div class="mt-10 vx-col ringtones__heading">
        <h3 class="ringtones__heading__title">{{ $t('views.ringtones.title') }}</h3>
        <p class="ringtones__heading__description">{{ $t('views.ringtones.description') }}</p>
      </div>
    </div>

    <input v-if="!hasTotalRingtonesLimitExceeded" type="file" id="file-input" @change="handleFileUpload" accept=".mp3,.wav" />
    <div v-if="!hasTotalRingtonesLimitExceeded">
      <!-- Custom file input label -->
      <label for="file-input" class="custom-file-input"> Choose File </label>
      <span class="file-chosen">{{ fileName || 'No file chosen' }}</span>
    </div>

    <div class="file-info">{{ $t('info.ringtoneValidation') }}</div>

    <div class="ringtone-items-container mt-5">
      <div v-for="(ringtone, i) in ringtones" :key="i" class="ringtone-item">
        <div v-if="!ringtone.isPathadviceDefault" class="ringtone-item--delete-icon" @click="deleteRingtone(ringtone)">
          <DeleteIcon color="#fff" />
        </div>
        <div class="filename">{{ getFileNameFromURL(ringtone) }}</div>
        <div class="ringtone-item--volume">
          <VolumeIcon color="#fff" stroke="#fff" />
          <vs-slider
            ticks
            :step="1"
            :step-decimals="true"
            :max="100"
            @input="adjustRingtoneVolume(ringtone, i)"
            v-model="ringtone.loudness"
            :disabled="isProcessing"
          />
        </div>

        <div class="ringtone-item--volume">
          <div @click="toggleDefaultRingtone(ringtone)">
            <toggle-switch-icon :width="24" :height="24" :enabled="ringtone.isDefault" />
          </div>
          <vs-button icon-pack="feather" size="medium" :icon="ringtone.isPlaying ? 'icon-pause' : 'icon-play'" @click="togglePlayPause(ringtone, i)">
          </vs-button>
        </div>

        <audio class="audio-player" :id="`rt-audio-player-${i}`" loop>
          <source :src="ringtone.url" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
    <vs-button color="primary" @click.prevent="saveRingtones" :disabled="isProcessing">{{ $t('vue.save') }}</vs-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import DeleteIcon from '@/components/icons/DeleteIcon'
import VolumeIcon from '@/components/icons/VolumeIcon.vue'
export default {
  components: {
    ToggleSwitchIcon,
    DeleteIcon,
    VolumeIcon
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      progressUpload: 0,
      ringtones: [],
      isProcessing: false,
      fileName: ''
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    userRingtones() {
      return this.activeUserInfo && this.activeUserInfo.ringtones ? this.activeUserInfo.ringtones : []
    },
    hasTotalRingtonesLimitExceeded() {
      return this.userRingtones.filter((x) => !x.isPathadviceDefault).length >= 5
    }
  },
  mounted() {
    this.ringtones = this.userRingtones.map((ringtone) => JSON.parse(JSON.stringify(ringtone)))
    this.ringtones.forEach((ringtone) => {
      if (typeof ringtone.loudness === 'undefined') {
        ringtone.loudness = 60
      }
      ringtone.isPlaying = false
    })

    const hasDefaultRingtone = this.ringtones.find((x) => x.isDefault && !x.isPathadviceDefault)
    const hasPathadviceRingtone = this.ringtones.find((x) => x.isPathadviceDefault)

    if (!hasPathadviceRingtone) {
      this.ringtones.unshift({
        url: require('@/assets/visitor_request.mp3'),
        loudness: 100,
        isDefault: Boolean(!hasDefaultRingtone),
        isPathadviceDefault: true
      })
    }
  },
  watch: {
    'activeUserInfo.ringtones'() {
      this.ringtones = this.userRingtones.map((ringtone) => JSON.parse(JSON.stringify(ringtone)))
      this.ringtones.forEach((ringtone) => {
        if (typeof ringtone.loudness === 'undefined') {
          ringtone.loudness = 60
        }
      })
    }
  },
  methods: {
    togglePlayPause(ringtone, i) {
      const audioPlayer = document.getElementById(`rt-audio-player-${i}`)
      if (ringtone.isPlaying) {
        audioPlayer.pause()
      } else {
        audioPlayer.play()
      }
      ringtone.isPlaying = !ringtone.isPlaying
      this.$forceUpdate()
    },
    adjustRingtoneVolume(ringtone, i) {
      const audioPlayer = document.getElementById(`rt-audio-player-${i}`)
      audioPlayer.volume = ringtone.loudness / 100
    },
    async toggleDefaultRingtone(ringtone) {
      if (!this.ringtones || this.ringtones.length === 1) return
      const isDefault = !ringtone.isDefault
      this.ringtones.forEach((tone) => {
        tone.isDefault = false
      })
      ringtone.isDefault = isDefault

      await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ ringtones: this.ringtones }, { merge: true })
    },
    async deleteRingtone(ringtone) {
      this.ringtones = this.ringtones.filter((x) => x.url !== ringtone.url)
      await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ ringtones: this.ringtones }, { merge: true })
      await this.onDeleteRingtone(ringtone)
    },
    getFileNameFromURL(ringtone) {
      let url = ''
      if (ringtone.isPathadviceDefault) {
        url = `https://app.letsconnect.at${ringtone.url}`
      } else {
        url = ringtone.url
      }
      // Create a URL object from the URL string
      const parsedUrl = new URL(url)

      // Get the pathname part of the URL, which includes the file name
      const pathname = decodeURIComponent(parsedUrl.pathname)

      // Split the pathname into parts divided by '/'
      const parts = pathname.split('/')

      // The file name is the last part of the array
      const fileName = parts[parts.length - 1]

      if (ringtone.isPathadviceDefault) return "Let's Connect"

      return fileName // Decode any percent-encoded characters
    },
    async handleFileUpload(event) {
      this.isProcessing = true
      const file = event.target.files[0]
      if (!file) return

      this.fileName = file.name

      // Validate file size
      const maxSize = 800 * 1024 // 800 KB in bytes
      if (file.size > maxSize) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.fileSizeLimitExceeded'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        event.target.value = '' // Clear the input field
        this.isProcessing = false
        this.fileName = ''
        return
      }

      await this.$vs.loading()

      // Construct the storage path
      const path = `ringtones/${this.activeUserInfo.company}/${this.activeUserInfo.uid}/${file.name}`
      const storageRef = this.$firebase.storage().ref(path)

      try {
        // Upload the file to Firebase Storage
        this.uploadTask = storageRef.put(file)
        this.uploadTask.on(
          'state_changed',
          (snapshot) => {
            this.progressUpload = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          },
          (err) => {
            this.$vs.notify({
              time: 8800,
              title: this.$i18n.t('vue.error'),
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
            event.target.value = ''
            this.fileName = ''
            this.isProcessing = false
          }
        )
        this.uploadTask
          .then(({ ref }) => {
            return ref.getDownloadURL()
          })
          .then(async (url) => {
            const ringtone = {
              url,
              isDefault: false,
              loudness: 60
            }
            this.$db
              .collection('users')
              .doc(this.activeUserInfo.uid)
              .set({ ringtones: this.$firebase.firestore.FieldValue.arrayUnion(ringtone) }, { merge: true })
            this.isProcessing = false
            event.target.value = ''
            this.fileName = ''
            await this.$vs.loading.close()
          })
      } catch (error) {
        //eslint-disable-next-line
        console.error('Error uploading file:', error)
        alert('Error uploading file!')
        this.isProcessing = false
        event.target.value = ''
        this.fileName = ''
        await this.$vs.loading.close()
      }
    },
    async onDeleteRingtone(ringtone) {
      try {
        // Construct the storage path
        const storageService = this.$firebase.storage()
        await storageService.refFromURL(ringtone.url).delete()
      } catch (error) {
        this.isProcessing = false
        // eslint-disable-next-line
        console.log(error.message)
      }
    },

    async saveRingtones() {
      this.isProcessing = true
      try {
        let _ringtones = this.ringtones.map((ringtone) => JSON.parse(JSON.stringify(ringtone)))
        _ringtones = _ringtones.map((tone) => {
          return {
            url: tone.url,
            loudness: tone.loudness,
            isDefault: tone.isDefault,
            isPathadviceDefault: Boolean(tone.isPathadviceDefault)
          }
        })

        await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ ringtones: _ringtones }, { merge: true })
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } catch (error) {
        //eslint-disable-next-line
        console.log(error.message)
      }
      this.isProcessing = false
    }
  }
}
</script>
<style lang="scss">
.user-settings-ringtone {
  input[type='file'] {
    display: none; // Hide the native file input
  }

  .ringtone-items-container {
    display: flex; /* Display items in a row */
    flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
    gap: 10px; /* Space between items */
  }

  .custom-file-input {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgba(var(--vs-primary), 1);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
  }

  .file-chosen {
    margin-left: 10px;
    font-size: 14px;
    color: #888;
  }
  .file-info {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
  }
  .ringtone-item {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 10px 15px 10px 10px;
    margin-bottom: 10px;
    background-color: #25a2f4;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #fff;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 45%;
    }

    &--delete-icon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      cursor: pointer;
      /* Style your delete icon (e.g., color, background) */
    }

    &--volume {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .filename,
  .status {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .audio-player {
    width: 100%; /* Responsive width */
    outline: none; /* Removes default focus outline */
    margin-top: 10px;
  }

  .ringtones {
    &__heading {
      margin: 0 auto;
      max-width: 760px;
      text-align: center;
      color: #262629;

      &__title {
        color: #262629;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
      }

      &__description {
        margin-top: 15px;
        font-size: 16px;
      }
    }
  }
}
</style>
